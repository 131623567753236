import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import 'aos/dist/aos.css';
import AOS from 'aos';
AOS.init();

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContactForm = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await emailjs.send('service_cig68lj', 'template_59dlgew', formData, 'NqsE9VJlynZsmZDY7');
      setSnackbar({ open: true, message: 'Message sent successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbar({ open: true, message: 'An error occurred. Please try again later.', severity: 'error' });
    }
  };

  return (
    <section id="contact" className="contact-us" data-aos="fade-up">
      <div className="container">
        <h6 className="sub-heading">Contact Us</h6>
        <h2 className="mb-5">Get in Touch <span className="heading-animate">with Us</span></h2>
        <div className="col-lg-12">
          <div className="contact-us-content">
            <form id="contact-form" ref={form} onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <fieldset>
                    <input type="text" name="name" id="name" placeholder="Your Name" onChange={handleChange} autoComplete="on" required />
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <input type="text" name="email" id="email" placeholder="Your E-mail" onChange={handleChange} autoComplete="on" required />
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <input type="number" name="phone" id="number" placeholder="Your Phone Number" onChange={handleChange} autoComplete="on" required />
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <textarea name="message" id="message" placeholder="Your Message" onChange={handleChange} required></textarea>
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <button type="submit" id="form-submit" className="orange-button">Send Message</button>
                  </fieldset>
                </div>
              </div>
            </form>
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
              <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;