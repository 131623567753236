import React from "react";
import MetaTags from 'react-meta-tags';
import { Grid } from "@mui/material";
import PaatentDrafting from '../assets/images/PaatentDrafting.svg'
import ScrollToTop from "../components/ScrollToTop";
import 'aos/dist/aos.css';
import AOS from 'aos';
AOS.init();

const PatentDrafting = () => {
    return(
        <>
        <ScrollToTop />
        <MetaTags>
            <title>Saras IP | Patent Drafting Services</title>
            <meta name="description" content="Discover Saras IP's professional patent drafting services. Our expert team assists in drafting clear and comprehensive patent applications to protect your innovations effectively." />
        </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Patent Drafting</h1>
                        <p>Unleash the power of intellectual property</p>
                    </div>
                </div>
            </section>
            <section id='services-details-top' className='container' data-aos="fade-up">
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={5} sm={5} display="flex" alignItems="center" justifyContent="center">
                        <div>
                            <img src={PaatentDrafting} alt="Patent Analytics" className="img-fluid patent-service-img" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} margin="auto">
                        <p>Patent drafting is a crucial and methodical process for obtaining legal protection for innovations. It involves crafting detailed documents that highlight the unique aspects of an invention, emphasizing its utility, uniqueness, and non-obviousness. The two main types of patent applications are provisional and non-provisional. Provisional applications secure an initial filing date, providing inventors with time for refinements before submitting a formal non-provisional application. Successful patent drafting requires precision in language, clear descriptions, and well-articulated claims to meet the stringent criteria set by patent offices. Beyond the initial filing, services may include proofreading for accuracy and compliance, as well as translation for global protection. Additionally, continuation applications and design patent applications may be considered to strengthen intellectual property portfolios. Essentially, patent drafting forms the cornerstone of the patent application process, laying the groundwork for obtaining exclusive rights and safeguarding the intellectual property of inventors and innovators.</p>
                    </Grid>
                </Grid>
            </section>
            <section id='services-details' data-aos="fade-up">
                <div className='container service-container'>
                    <ul className="service-list">
                        <li>Provisional Patent Application Drafting:</li>
                        <p>Crafting a solid foundation for patent protection, provisional patent application drafting involves preparing a preliminary document that outlines the invention's key aspects. While providing an early filing date, it offers inventors more time to refine their innovations before filing a non-provisional application.</p>

                        <li>Non-Provisional Patent Application Drafting:</li>
                        <p>Non-provisional patent application drafting involves creating a comprehensive document that includes detailed descriptions, claims, and supporting information. This application serves as the formal request for patent protection, outlining the invention's novelty, utility, and non-obviousness to meet patent office requirements.</p>
                        
                        <li>Patent Proofreading:</li>
                        <p>Ensuring accuracy and adherence to patent office standards, patent proofreading involves a meticulous review of drafted patent documents. This process aims to identify and rectify errors, inconsistencies, and potential issues that could impact the patent's quality and enforceability.</p>

                        <li>Patent Translation and Refinement:</li>
                        <p>In the global landscape of innovation, patent translation and refinement services play a crucial role. This involves translating patent documents into different languages to secure protection in multiple jurisdictions while ensuring that the translated content maintains the accuracy and legal nuances of the original text.</p>

                        <li>Continuation Application Claims Drafting:</li>
                        <p>Continuation application claims drafting involves extending the protection of an existing patent. By filing a continuation application, inventors can introduce new claims or amendments, allowing them to address potential challenges, broaden the scope of protection, or adapt to changes in the competitive landscape.</p>

                        <li>Continuation-in-Parts Application Drafting:</li>
                        <p>Building upon the original application, continuation-in-parts (CIP) application drafting involves creating a new application that includes both the original disclosure and additional innovations. This strategy is employed when inventors have made substantial improvements or modifications to their original invention.</p>

                        <li>Design Patent Application Drafting:</li>
                        <p>Focused on the aesthetic aspects of an invention, design patent application drafting entails preparing documents that emphasize the visual characteristics. These include drawings, photographs, or other visual representations that highlight the unique and ornamental features of the design, ensuring comprehensive protection for the inventive design elements.</p>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default PatentDrafting;