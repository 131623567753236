// Core
import React from 'react';
// Library
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// Components
import Header from './components/Header';
import Footer from './components/Footer';
// Pages
import Homepage from './pages/Homepage';
import PatentAnalytics from './pages/PatentAnalytics';
import PatentDrafting from './pages/PatentDrafting';
import PatentFiling from './pages/PatentFiling';
import PatentPro from './pages/PatentPro';
import Sitemap from './pages/Sitemap';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefreshPage from './components/RefreshPage';
import PageNotFound from './pages/Error404';

function App() {
    return (
        <div>
            <Router>
                <RefreshPage />
                <Header />
                <Switch>
                    <Route exact path='/' component={Homepage} />
                    <Route exact path='/patent-analytics' component={PatentAnalytics} />
                    <Route exact path='/patent-drafting' component={PatentDrafting} />
                    <Route exact path='/patent-filing' component={PatentFiling} />
                    <Route exact path='/patent-prosecution' component={PatentPro} />
                    <Route exact path='/sitemap' component={Sitemap} />
                    <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                    <Route component={PageNotFound} />   
                </Switch>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
