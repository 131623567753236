export const menuItems = [
    {
        label: 'Home',
        path: '/#hero',
        icon: '',
    },
    {
        label: 'Services',
        path: '/#services',
        icon: '',
    },
    {
        label: 'Technology Domain',
        path: '/#domain',
        icon: '',
    },
    {
        label: 'FAQ',
        path: '/#FAQ',
        icon: '',
    },
    {
        label: 'Contact Us',
        path: '/#contact',
        icon: '',
    }
]
