import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import emailjs from 'emailjs-com';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function FormDialog() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setName('');
    setPhoneNumber('');
    setEmail('');
    setMessage('');
    setSelectedService('');
    setOpen(false);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleServiceChange = (event) => {
    setSelectedService(event.target.value);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSubscribe = async () => {
    try {
      const QuoteFormData = {
        name,
        phone: phoneNumber,
        email,
        message,
        service: selectedService,
      };

      const response = await emailjs.send('service_cig68lj', 'template_no6yzjg', QuoteFormData, 'NqsE9VJlynZsmZDY7');
      setSnackbar({ open: true, message: 'Message sent successfully!', severity: 'success' });
      } 
    
    catch (error) {
      setSnackbar({ open: true, message: 'An error occurred. Please try again later.', severity: 'error' });
      } 
    
    finally {
      handleClose();
    }
  };

  const renderFormFields = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={name}
          onChange={handleNameChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          id="phoneNumber"
          label="Phone Number"
          type="number"
          fullWidth
          variant="outlined"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={handleEmailChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="service-label">Service</InputLabel>
          <Select
            labelId="service-label"
            id="service"
            value={selectedService}
            onChange={handleServiceChange}
            label="Service"
          >
            <MenuItem value="Patent Filing">Patent Filing</MenuItem>
            <MenuItem value="Patent Drafting">Patent Drafting</MenuItem>
            <MenuItem value="Patent Analytics">Patent Analytics</MenuItem>
            <MenuItem value="Patent Prosecution">Patent Prosecution</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          id="message"
          label="Message"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={message}
          onChange={handleMessageChange}
        />
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Request A Quote
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Request A Quote</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the details to request a quote.
          </DialogContentText>
          {renderFormFields()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubscribe} color="primary" disabled={!email.trim()}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}