import React from 'react'
import error404 from '../assets/images/error-404.svg'

const PageNotFound = () => {
    return (
        <>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Page not found</h1>
                        <p>Error 404</p>
                    </div>
                </div>
            </section>
            <section>
                <div className="container text-center error-image">
                    <img src={error404} alt="Error 404" className="img-fluid" />
                </div>
            </section>
        </>
    )
}

export default PageNotFound;