import React from "react";
import MetaTags from 'react-meta-tags';
import { Grid } from "@mui/material";
import PaatentPro from '../assets/images/PaatentPro.svg'
import ScrollToTop from "../components/ScrollToTop";
import 'aos/dist/aos.css';
import AOS from 'aos';
AOS.init();

const PatentPro = () => {
    return(
        <>
        <ScrollToTop />
        <MetaTags>
            <title>Saras IP | Patent Prosecution Services</title>
            <meta name="description" content="Saras IP offers expert patent prosecution services to protect your inventions. Our experienced team is dedicated to protecting your inventions and guide you through the patent process with precision and expertise." />
        </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Patent Prosecution</h1>
                        <p>Unleash the power of intellectual property</p>
                    </div>
                </div>
            </section>
            <section id='services-details-top' className='container' data-aos="fade-up">
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={5} sm={5} display="flex" alignItems="center" justifyContent="center">
                        <div>
                            <img src={PaatentPro} alt="Patent Analytics" className="img-fluid patent-service-img" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} margin="auto">
                        <p>Patent prosecution is a strategic process within intellectual property that involves interactions between applicants and patent offices. It primarily includes crafting responses to Examination Reports or Office Actions, addressing examiner queries to strengthen the patent application. Interviews with patent examiners provide a direct communication channel, facilitating clarifications and arguments to potentially expedite the examination. In contested situations, responding to Inter Partes Review (IPR) petitions becomes crucial, necessitating detailed replies to challenges by third parties seeking patent invalidation. Additionally, patent prosecution covers responses to Ex-Parte Examination, initiated by the applicant to address examiner concerns and support application approval. Ensuring patent longevity involves timely payment of maintenance fees. In essence, patent prosecution is a nuanced interplay of actions and responses, strategically maneuvering through the intricacies of examination and maintenance for sustained intellectual property rights.</p>
                    </Grid>
                </Grid>
            </section>
            <section id='services-details' data-aos="fade-up">
                <div className='container service-container'>
                    <ul className="service-list">
                        <li>Response to Office Actions/Examination Reports:</li>
                        <p>Addressing Office Actions or Examination Reports is a key element of patent prosecution. This involves crafting comprehensive and strategic responses to queries or concerns raised by patent examiners during the examination process. These responses aim to clarify and strengthen the patent application, increasing the likelihood of successful prosecution.</p>

                        <li>Interviews with Patent Examiners:</li>
                        <p>Facilitating direct communication with patent examiners, interviews play a vital role in patent prosecution. Conducted to discuss the application's merits, clarify issues, or present arguments, these interactions provide an opportunity to navigate challenges and enhance the examiner's understanding, potentially expediting the examination process.</p>
                        
                        <li>Response to Inter Partes Review (IPR) Petition:</li>
                        <p>Responding to Inter Partes Review (IPR) petitions is a critical aspect of patent prosecution, particularly in contested cases. This involves preparing detailed responses to challenges raised by third parties seeking to invalidate a patent. A robust response is essential to defend the patent's validity during the review process.</p>

                        <li>Response to Ex-Parte Examination:</li>
                        <p>In the context of patent prosecution, responding to Ex-Parte Examination involves addressing examination proceedings initiated by a single party, typically the patent applicant. Crafted responses aim to address concerns raised by examiners, providing additional information or arguments to support the patent application's approval.</p>

                        <li>Maintenance Fee Payments:</li>
                        <p>Ensuring the continuity of patent protection involves timely maintenance fee payments. These payments, typically due at specified intervals after the patent grant, are essential to keep the patent in force. Failure to make these payments can lead to the expiration of the patent, emphasizing the importance of diligent and strategic fee management in the patent prosecution process.</p>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default PatentPro;