import React from "react";
import MetaTags from 'react-meta-tags';
import { Grid } from "@mui/material";
import PaatentFiling from '../assets/images/PaatentFiling.svg'
import ScrollToTop from "../components/ScrollToTop";
import 'aos/dist/aos.css';
import AOS from 'aos';
AOS.init();

const PatentFiling = () => {
    return(
        <>
        <ScrollToTop />
        <MetaTags>
            <title>Saras IP | Patent Filing Services</title>
            <meta name="description" content="Saras IP provides expert patent filing services to secure your innovations. Our team ensures thorough preparation and submission of patent applications, guiding you through the filing process with precision and expertise." />
        </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Patent Filing</h1>
                        <p>Unleash the power of intellectual property</p>
                    </div>
                </div>
            </section>
            <section id='services-details-top' className='container' data-aos="fade-up">
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={5} sm={5} display="flex" alignItems="center" justifyContent="center">
                        <div>
                            <img src={PaatentFiling} alt="Patent Analytics" className="img-fluid patent-service-img" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} margin="auto">
                        <p>Patent filing is a pivotal step in securing intellectual property rights for innovative concepts. It involves the submission of meticulously prepared documents formally requesting protection for an invention. Provisional application filing marks the initial phase, establishing an early filing date and allowing time for refinements before advancing to a non-provisional application. The latter, a comprehensive submission, comprises detailed descriptions and claims, presenting a robust case for the invention's novelty and non-obviousness to meet patent office requirements. For international protection, PCT (Patent Cooperation Treaty) application filing streamlines the process, offering a single application to secure an international filing date before deciding on individual country filings. Design patent application filing, tailored for protecting visual and ornamental aspects, involves submitting documents emphasizing unique design features. Overall, patent filing is the formal mechanism through which inventors seek exclusive rights and safeguard their intellectual property globally.</p>
                    </Grid>
                </Grid>
            </section>
            <section id='services-details' data-aos="fade-up">
                <div className='container service-container'>
                    <ul className="service-list">
                        <li>Provisional Application Filing:</li>
                        <p>Initiating the patent protection process, provisional application filing involves submitting a preliminary document that outlines the essential features of an invention. While providing inventors with an early filing date, it allows for a grace period during which further refinements can be made before pursuing a non-provisional application.</p>

                        <li>Non-Provisional Application Filing:</li>
                        <p>Non-provisional application filing is a crucial step in formally seeking patent protection. This comprehensive submission includes detailed descriptions, claims, and supporting information, presenting a thorough case for the invention's novelty, utility, and non-obviousness to satisfy the requirements of patent offices.</p>
                        
                        <li>PCT Application Filing:</li>
                        <p>Engaging with the international patent system, PCT (Patent Cooperation Treaty) application filing provides inventors with a streamlined approach to seek protection in multiple countries. By filing a single application, inventors can secure an international filing date and delay the decision on individual country filings, offering flexibility in navigating diverse patent systems.</p>

                        <li>Design Patent Application Filing:</li>
                        <p>Tailored to protect the visual and ornamental aspects of an invention, design patent application filing involves submitting documents that emphasize the unique design features. This typically includes drawings, photographs, or other visual representations that highlight the aesthetic elements of the design, ensuring comprehensive protection for the inventive visual aspects.</p>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default PatentFiling;